// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2021_11-module--maineDigest--pwD4Q";
export var heroImage = "maine_digest_2021_11-module--heroImage--qMc+D";
export var columnWrapper = "maine_digest_2021_11-module--columnWrapper--goq91";
export var headingLogo = "maine_digest_2021_11-module--headingLogo--0ONqn";
export var headingWrapper = "maine_digest_2021_11-module--headingWrapper--ShzkM";
export var heading = "maine_digest_2021_11-module--heading--Yy7BR";
export var instance = "maine_digest_2021_11-module--instance--IuEkP";
export var subheading = "maine_digest_2021_11-module--subheading--DOJyY";
export var textWrapper = "maine_digest_2021_11-module--textWrapper--C4JTG";
export var latestnewsarticleheadline = "maine_digest_2021_11-module--latestnewsarticleheadline--txrKn";
export var dateline = "maine_digest_2021_11-module--dateline--b3fO6";
export var whatsNew = "maine_digest_2021_11-module--whatsNew--qGI8Q";
export var photosWrapper = "maine_digest_2021_11-module--photosWrapper--5D9zp";
export var photo = "maine_digest_2021_11-module--photo--W4ySq";
export var photoCaption = "maine_digest_2021_11-module--photoCaption--rwQo6";
export var rightPhotoCaption = "maine_digest_2021_11-module--rightPhotoCaption--EnrMC";
export var rightPhoto = "maine_digest_2021_11-module--rightPhoto--4eyUi";
export var rightCaptions = "maine_digest_2021_11-module--rightCaptions--gO0TU";
export var afterCaption = "maine_digest_2021_11-module--afterCaption--8y0Iy";